@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common components */
@import "./css/variables.css";
@import "./css/common/buttons.css";
@import "./css/common/headings.css";
@import "./css/common/loaders.css";
@import "./css/common/modals.css";
@import "./css/common/number-input.css";
@import "./css/common/colour-picker.css";
@import "./css/common/text.css";
@import "./css/common/widgets.css";
@import "./css/common/icons.css";
@import "./css/common/scrollbar.css";
@import "./css/common/switch.css";

/* Features */
@import "./css/features/collab-docs.css";
@import "./css/features/journeys.css";
@import "./css/features/nav.touch.css";
@import "./css/features/layout.css";
@import "./css/features/success.tick";
@import "./css/features/forms.css";

/*Fonts*/
@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProThin"),
    url("./fonts/SofiaProThin/SofiaProThin.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProThin"),
    url("./fonts/SofiaProThin/SofiaProThinItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProExtraLight"),
    url("./fonts/SofiaProExtraLight/SofiaProExtraLight.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProExtraLight"),
    url("./fonts/SofiaProExtraLight/SofiaProExtraLightItalic.otf")
      format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProLight"),
    url("./fonts/SofiaProLight/SofiaProLight.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProLight"),
    url("./fonts/SofiaProLight/SofiaProLightItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProRegular"),
    url("./fonts/SofiaProRegular/SofiaProRegular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProRegular"),
    url("./fonts/SofiaProRegular/SofiaProRegularItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProMedium"),
    url("./fonts/SofiaProMedium/SofiaProMedium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProMedium"),
    url("./fonts/SofiaProMedium/SofiaProMediumItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProSemiBold"),
    url("./fonts/SofiaProSemiBold/SofiaProSemiBold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProSemiBold"),
    url("./fonts/SofiaProSemiBold/SofiaProSemiBoldItalic.otf")
      format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProBold"),
    url("./fonts/SofiaProBold/SofiaProBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProBold"),
    url("./fonts/SofiaProBold/SofiaProBoldItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProBlack"),
    url("./fonts/SofiaProBlack/SofiaProBlack.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaProBlack"),
    url("./fonts/SofiaProBlack/SofiaProBlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
