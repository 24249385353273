/*Standard Headings (Dark)*/
h1,
#main-container h1,
h1.standard-heading {
  @apply text-3xl font-bold mt-2 mb-4 text-gray-800;
}

h2,
#main-container h2,
h2.standard-heading {
  @apply text-2xl font-semibold mt-1 mb-2 text-gray-800;
}

h3,
#main-container h3,
h3.standard-heading {
  @apply text-xl font-semibold mt-1 mb-2 text-gray-800;
}

/*Coloured Headings (Primary colour)*/
h1.coloured-heading {
  @apply text-3xl font-bold mt-2 mb-4;
  color: var(--color-primary);
}

h2.coloured-heading {
  @apply text-2xl font-semibold mt-1 mb-2;
  color: var(--color-primary);
}

#top-bar h3,
h3.coloured-heading {
  @apply text-2xl font-semibold mt-1 mb-2;
  color: var(--color-primary) !important;
}

h4.coloured-heading {
  @apply text-lg font-semibold mt-1 mb-2;
  color: var(--color-primary) !important;
}

h5.coloured-heading {
  @apply text-base font-semibold mt-1 mb-2;
  color: var(--color-primary) !important;
}

h6.coloured-heading {
  @apply text-sm font-semibold mt-1 mb-2;
  color: var(--color-primary) !important;
}

/*Light Heading (White, used for Joruneys etc)*/
#main-container h1.light-heading,
h1.light-heading {
  @apply text-3xl font-bold mt-2 mb-4 text-white/95;
}

#main-container h2.light-heading,
h2.light-heading {
  @apply text-2xl font-semibold mt-1 mb-2 text-white/95;
}
#main-container h3.light-heading,
h3.light-heading {
  @apply text-xl font-semibold mt-1 mb-2 text-white/95;
}

/*Header Select List*/
select.header-select {
  @apply text-lg font-bold py-1 px-6 my-0 mx-1 outline-0 focus:outline-none focus:outline-0 active:outline-none active:outline-0 border-b-gray-300 border-x-0 border-t-0 bg-transparent;
  color: var(--color-primary);
}
