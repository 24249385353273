/* Styles for the competency "More info" popup in journeys and collab docs */
.competency-info-popup ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}

/* Month and year dropdowns in the datepicker */
.datepicker-container select {
  @apply text-sm py-1;
}
