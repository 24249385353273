body {
  overflow-y: hidden;
}

.active-nav-link {
  @apply bg-gray-200 py-2 px-4 my-2 mx-4 font-bold flex-nowrap text-sm rounded-md;
  color: var(--color-primary);
}

.inactive-nav-link {
  @apply py-2 px-4 my-2 mx-4 font-normal flex-nowrap text-sm text-gray-400;
}
.inactive-nav-link:hover {
  @apply bg-gray-200 rounded-md;
}

#app-body {
  flex: 1 1 auto;
  overflow-y: auto;
}

.inactive-nav-link .nav-icon-active{
  display: none;
}

.active-nav-link .nav-icon-inactive{
  display: none;
}