.accent-text {
  color: var(--color-accent);
}
.accent-color {
  background-color: var(--color-accent);
}
.primary-text {
  @apply font-semibold;
  color: var(--color-primary);
}
a.text-link {
  @apply underline;
}
.primary-color,
.primary-colour {
  color: var(--color-primary);
}